import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";

export const InvoiceModal = (props) => {
  const { data, open, handleClose, onSubmitHandler } = props;
  // console.log(props)
  const confirmHandler = () => {
    const emailContent = `
    <div style="padding: 12px 16px;">
      Dear ${data.name}, <br /><br />
      Please find here the invoice link for the above interpretation/translation booking. <br /><br />
      <div style="margin: 12px 0px 30px;">
      <span style="font-weight: bolder; margin-right: 12px;">Click here to</span>
      <a style="color: #ffffff;background: #e30815;width: 200px; padding: 0.5rem 0.8rem;border-radius: 4px;text-decoration: none;font-weight: bold;" href="${
        process.env.REACT_APP_OTHER_LINK_ENDPOING
      }/approve/invoice.html?id=${data._id}&service=${data.serviceType}&view=${
      process.env.REACT_APP_MODE
    }" target="_blank" title=${data.booking_ref + " Click me"}>
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 489 489" style="enable-background:new 0 0 489 489;" xml:space="preserve"
            width="18px" fill="#fff">
            <g>
              <g>
                <path d="M329.2,327.2c-4.5,0-8.1,3.4-8.6,7.9c-3.9,38.6-36.5,68.7-76.2,68.7c-39.6,0-72.2-30.1-76.2-68.7
             c-0.5-4.4-4.1-7.9-8.6-7.9h-104c-21.8,0-39.5,17.7-39.5,39.5v82.8c0,21.8,17.7,39.5,39.5,39.5h377.8c21.8,0,39.5-17.7,39.5-39.5
             v-82.7c0-21.8-17.7-39.5-39.5-39.5H329.2V327.2z" />
                <path d="M303.5,198.6l-30.9,30.9V28.1C272.6,12.6,260,0,244.5,0l0,0c-15.5,0-28.1,12.6-28.1,28.1v201.4l-30.9-30.9
             c-9.5-9.5-24.7-11.9-35.9-4.4c-15.3,10.2-16.8,31.1-4.5,43.4l82.8,82.8c9.2,9.2,24.1,9.2,33.3,0l82.8-82.8
             c12.3-12.3,10.8-33.2-4.5-43.4C328.2,186.6,313,189,303.5,198.6z" />
              </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
          </svg>
          &nbsp;
          View Invoice</a>
    </div>
      <div style="margin: 8px 0px">
        <b style="color:#000099"><u>Rate or Review us.</u></b><br/>
        <p>
          If you have a moment to spare, we would appreciate if you could tell us how you found our services.
        </p>
        <p>
          Please rate and leave your comments on our Google or Trustpilot page.
        </p>
        <table>
          <tbody>
            <tr>
              <td width="220px">
                  <a href="https://uk.trustpilot.com/evaluate/language-interpreters.com" target="_blank">Click here</a> to submit a review <br/>
                  <img src="${
                    process.env.REACT_APP_TRUSTPILOT_REVIEW_LOGO
                  }" alt="trustpilot logo" width="155" />
            
              </td>
              <td>
                  <a href="https://g.page/r/CVg1cNT08ma_EAI/review" target="_blank">Click here</a> to submit a review <br />
                  <img src="${
                    process.env.REACT_APP_GOOGLE_REVIEW_LOGO
                  }" alt="google logo" width="155" />
              </td>
            </tr>
          </tbody>
        </table>
       
       
        <p>
          Thank you for using our services.
        </p>
        <p>
          If you need to contact the Accounts urgently for any reason, please email accounts@language-interpreters.com or call us on 0208 123 5556 for assistance.
        </p>
      </div>  
      <p>Kind regards</p>
      <p>
          <b>Language Interpreters Ltd.</b><br />
          T- <a href="tel:020 8123 5556">020 8123 5556</a>
          <br />
          E- <a href="mailto:020 8123 5556">info@language-interpreters.com</a>
          <br />
          W- <a href="https://language-interpreters.com/" target="_blank">www.language-interpreters.com </a> <br/>
          A – 124 City Road , London EC1V 2NX. <br>
      </p>
      <img src="${process.env.REACT_APP_LOGO}" alt="logo" width="220" />
      <p  style="margin: 0px">
        Company Reg No: 12667551 <br />
        VAT No: 432 3549 09
      </p>
    
      <div style="margin: 20px 0px; border-top: 1px solid #dbdbdb;">
        <p style="font-size: 8px; font-weight: 700; color: #616d7e; text-align: justify;">
            DISCLAIMER: This email and any files transmitted with it are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you are not the named addressee you should not disseminate, distribute, copy this e-mail or taking any action in reliance on the contents of this information is strictly prohibited. Please notify the sender immediately by e-mail if you have received this e-mail by mistake and delete from your system..
            WARNING: Although Language Interpreters has taken reasonable precautions to ensure no viruses are present in this email, Language Interpreters cannot accept responsibility for any loss or damage arising from the use of this email or attachments.
        </p>
      </div>
    </div>`;

    try {
      const sendItem = {
        // to: [{ email: data.invoiceTo }, { email: data.email }],
        to:
          data?.invoiceTo !== data?.email
            ? [{ email: data?.email }, { email: data?.invoiceTo }]
            : [{ email: data?.email }],
        cc: process.env.REACT_APP_INVOICE_EMAIL,
        from: {
          email: process.env.REACT_APP_INVOICE_EMAIL,
          name: process.env.REACT_APP_INVOICE_EMAIL_SENDER_NAME,
        },
        subject: `Invoice | Ref: ${data.fileRef} / ${
          data.serviceType === "Telephone" ||
          data.serviceType === "Video Call" ||
          data.serviceType === "Onsite"
            ? data.serviceType + " Interpretation"
            : data.serviceType
        }  / ${data.language} / ${data.booking_ref}`,
        content: [
          {
            type: "text/html",
            value: emailContent,
          },
        ],
      };
      onSubmitHandler(sendItem);
      closeHandler();
    } catch (error) {
      console.log(error);
    }
  };

  const closeHandler = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{"Invoice"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to send mail?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={confirmHandler}
            color="primary"
            variant="contained"
            autoFocus
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
