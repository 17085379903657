import React from "react";

import { lighten, makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  Toolbar,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  AppBar,
  Tooltip,
  Button,
  IconButton,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from "@material-ui/icons/ClearAll";

const useToolbarStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  toolbarMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: lighten(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: lighten(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  bookingInput: {
    marginRight: theme.spacing(1),
  },
  inputRoot: {
    color: "inherit",
  },
  secondSession: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row-reverse",
    },
  },
  clientTotalDiv: {
    background: "#fafafa",
    padding: "4px 8px",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    lineHeight: "0.78em",
    fontSize: "12px",
    alignItems: "flex-end",
    margin: "10px",
    "& span": {
      marginRight: "1em",
      backgroundColor: lighten(theme.palette.info.light, 0.7),
      padding: "10px 6px",
      borderRadius: 4,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      marginBottom: "6px",
      lineHeight: "1.4em",
      fontSize: 10,
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "80%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 2,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  listCount: {
    fontSize: "12px",
    fontWeight: 600,
    maxWidth: "100%",
    width: "160px",
    padding: "4px 6px",
    borderRadius: "4px",
    color: theme.palette.text.disabled,
    backgroundColor: lighten(theme.palette.action.disabled, 0.9),
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    title,
    handleChange,
    handleFromDateChange,
    handleToDateChange,
    handleChangeAutoComplete,
    values,
    isClients,
    handleInputChange,
    searchHandler,
    clearSearchHandler,
    handleBooking,
    totalAmount,
    totalCredit,
  } = props;

  return (
    <div className={classes.grow}>
      <AppBar position="sticky" color="transparent" elevation={1}>
        <div style={{ display: "flex", gap: 6, margin: "6px 15px -6px" }}>
          {totalAmount === 0 || (
            <span className={classes.listCount}>
              Total Amount: {totalAmount?.toFixed(2)}
            </span>
          )}
          {totalCredit === 0 || (
            <span className={classes.listCount}>
              Total Credit: {totalCredit?.toFixed(2)}
            </span>
          )}
        </div>
        <Toolbar className={classes.toolbarMobile}>
          <div style={{ display: "flex", flexDirection: "column-reverse" }}>
            <Tooltip title={title}>
              <Typography
                className={classes.title}
                style={{ width: 220 }}
                variant="h6"
                noWrap
              >
                {title}
              </Typography>
            </Tooltip>
          </div>
          <div className={classes.search}>
            <Autocomplete
              id="company"
              name="company"
              options={isClients}
              getOptionLabel={(option) => option?.companyName || ""}
              onChange={handleChangeAutoComplete("company")}
              value={values.company ? values.company : ""}
              style={{ width: 240 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Company"
                  size="small"
                  variant="outlined"
                  onChange={handleInputChange}
                  value={values.company ? values.company : ""}
                />
              )}
              disabled={values.private ? true : false}
            />
          </div>
          <div className={classes.bookingInput}>
            <TextField
              fullWidth
              label="Booking Ref"
              size="small"
              variant="outlined"
              name="bookingRef"
              onChange={handleBooking}
              value={values.bookingRef ? values.bookingRef : ""}
            />
          </div>
          <div className={classes.bookingInput}>
            <TextField
              fullWidth
              label="Payment Ref"
              size="small"
              variant="outlined"
              name="paymentRef"
              onChange={handleBooking}
              value={values.paymentRef ? values.paymentRef : ""}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isCredit}
                  onChange={handleChange}
                  name="isCredit"
                  color="primary"
                />
              }
              label="Credit"
            />

            <KeyboardDatePicker
              autoOk
              style={{ width: 180, marginRight: "4px" }}
              size="small"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              variant="inline"
              inputVariant="outlined"
              label="From"
              onChange={handleFromDateChange}
              value={values.fromDate}
            />

            <KeyboardDatePicker
              autoOk
              style={{ width: 180 }}
              size="small"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              variant="inline"
              inputVariant="outlined"
              label="To"
              onChange={handleToDateChange}
              value={values.toDate}
            />

            <div style={{ display: "flex", alignItems: "center" }}>
              &nbsp;
              <Button
                size="small"
                endIcon={<SearchIcon />}
                onClick={searchHandler}
              >
                Search
              </Button>
              {values.isSearch && (
                <Tooltip title={`Clear search`}>
                  <IconButton
                    color="primary"
                    aria-label="clear search inputs"
                    onClick={clearSearchHandler}
                  >
                    <ClearAllIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isClient}
                  onChange={handleChange}
                  name="isClient"
                  color="primary"
                />
              }
              label="Client"
            />

            <KeyboardDatePicker
              autoOk
              size="small"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              variant="inline"
              inputVariant="outlined"
              label="From"
              onChange={handleFromDateChange}
              value={values.fromDate}
            />

            <KeyboardDatePicker
              autoOk
              size="small"
              format="dd/MM/yyyy"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              variant="inline"
              inputVariant="outlined"
              label="To"
              onChange={handleToDateChange}
              value={values.toDate}
            />
          </div>
        </Toolbar>
      </AppBar>
      {/* {isClients.length !== 0 ? */}
      <div className={classes.secondSession}>
        <div className={classes.sectionDesktop}></div>
      </div>
      {/* : null} */}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};
