import React from "react";

import { lighten, makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import PaymentIcon from "@material-ui/icons/Payment";
// import PropTypes from 'prop-types';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
    display: "flex",
    flexDirection: "column-reverse",
    marginRight: "4px",
  },
  privatePay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: {
    width: 90,
  },
  listCount: {
    fontSize: "12px",
    fontWeight: 600,
    maxWidth: "100%",
    width: "160px",
    padding: "0px 6px",
    borderRadius: "4px",
    color: theme.palette.text.disabled,
    backgroundColor: lighten(theme.palette.action.disabled, 0.9),
  },
}));

export const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title, handleOpen, privateClientHandler, totalAmount } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
        <span className={classes.listCount}>
          Total Amount: {totalAmount.toFixed(2)}
        </span>
      </Typography>
      <div className={classes.privatePay}>
        {/* <label className={classes.label}>
          <Typography variant="caption" component="div">
            Private Clients
          </Typography></label> */}
        <Tooltip title="Private Clients List">
          <Button
            variant="outlined"
            size="small"
            style={{ width: 170 }}
            onClick={() => privateClientHandler()}
          >
            <PaymentIcon /> Private Payment
          </Button>
        </Tooltip>
      </div>
      <Tooltip title="Add Client Payment">
        <IconButton
          aria-label="add blog"
          color="primary"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <AddCircleIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  // numSelected: PropTypes.number.isRequired,
};
