import * as actionTypes from "../actionTypes";

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_START:
      console.log("loaded");
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.FETCH_RECORDS:
      return {
        ...state,
        loading: false,
        error: null,
        remittances: action.payload.data,
        total: action.payload.total,
        count: action.payload.count,
        totalAmount: action.payload.totalSum,
      };

    case actionTypes.FETCH_ERRORS:
      return {
        ...state,
        load: false,
        loading: false,
        error: action.payload,
      };

    case "GET_INTERPRETER_NAME":
      return {
        ...state,
        load: false,
        interpreters: action.payload,
      };

    default:
      return state;
  }
};
