import React, { useEffect, useContext, useState, useCallback } from "react";
import { ClientDueContext } from "../../../../context/states/clientDueState";
import { AuthContext } from "../../../../context/states/authState";

import {
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TableBody,
  TablePagination,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { EnhancedTableHead } from "./EnhancedTableHead";

import VisibilityIcon from "@material-ui/icons/Visibility";

import { TablePaginationActions } from "../../../UI/TablePaginationActions";
import { DueDetails } from "./dueDetails/DueDetails";
import { AddClientPayment } from "./addClientPayment";

const headCells = [
  { id: "slno", numeric: false, disablePadding: false, label: "Sl.No" },
  { id: "company", numeric: false, disablePadding: false, label: "Company" },
  {
    id: "count",
    numeric: true,
    disablePadding: false,
    label: "Number of Booking",
  },
  { id: "totalAmount", numeric: true, disablePadding: false, label: "Amount" },
  { id: "edit", numeric: false, disablePadding: false, label: "Action" },
];

export const DueLists = (props) => {
  const { classes, stableSort, getComparator, title } = props;
  const {
    loading,
    getDuesHandler,
    data,
    total,
    getDueHandler,
    dues,
    load,
    addClientAllocatePaymentHandler,
    submitteData,
    getClientsHandler,
    isClients,
    getPrivateClientDuesHandler,
    totalAmount,
  } = useContext(ClientDueContext);
  const { userData } = useContext(AuthContext);
  const matches = useMediaQuery("(min-width:1920px)");

  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company");
  const [dense, setDense] = useState(true);
  const [isDueModal, setIsDueModal] = useState(false);
  const [isName, setIsName] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [isPrivate, setIsPrivate] = useState(false);
  const [isPage, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);

  useEffect(() => {
    const query =
      isPage !== 0
        ? `?page=${isPage + 1}&limit=${rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;
    getDuesHandler(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, isPage]);

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [setOrder, setOrderBy, orderBy]
  );

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelecteds = data.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    },
    [setSelected]
  );

  const closeSubmitHandle = useCallback(() => {
    const query =
      isPage !== 0
        ? `?page=${isPage + 1}&limit=${rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;
    getDuesHandler(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDueModalHandler = useCallback((id, comp) => {
    setIsDueModal(true);
    const company = {
      id: comp._id,
      name: comp.company,
    };
    setIsName(company);
    getDueHandler(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openPrivateClientModalHandler = useCallback(() => {
    getPrivateClientDuesHandler();
    setIsPrivate(true);
    setIsDueModal(true);
  }, []);

  // DueDetail model
  const closeDueModalHandler = useCallback(() => {
    setIsDueModal(false);
    setIsPrivate(false);
  }, [setIsDueModal]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setRowsPerPage, setPage]
  );

  const addPayment = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closePaymentModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  const PendingDues = React.useMemo(
    () => (
      <DueDetails
        open={isDueModal}
        handleClose={closeDueModalHandler}
        closeSubmitHandle={closeSubmitHandle}
        isLoading={load}
        data={dues}
        companyData={isName}
        submitHandler={addClientAllocatePaymentHandler}
        approvedBy={userData.user}
        submitteData={submitteData}
        isPrivate={isPrivate}
      />
    ),
    [
      isDueModal,
      dues,
      load,
      isName,
      addClientAllocatePaymentHandler,
      userData,
      submitteData,
      closeSubmitHandle,
      closeDueModalHandler,
      isPrivate,
    ]
  );

  return (
    <React.Fragment>
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleOpen={addPayment}
        title={title}
        privateClientHandler={openPrivateClientModalHandler}
        totalAmount={totalAmount}
      />
      <TableContainer
        className={matches ? classes.container2 : classes.container}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          className={classes.table}
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  rowSpan={6}
                  colSpan={12}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow hover>
                <TableCell
                  style={{ textAlign: "center" }}
                  rowSpan={6}
                  colSpan={12}
                >
                  No record
                </TableCell>
              </TableRow>
            ) : (
              stableSort(data, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={row._id}>
                      <TableCell></TableCell>
                      <TableCell align="left">
                        {index + 1 + isPage * rowsPerPage}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row?.company}
                      </TableCell>
                      <TableCell align="right">{row?.count}</TableCell>
                      <TableCell align="right">
                        {row?.totalAmount?.toFixed(2)}
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title={`Pending Due: ${row?.company}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => openDueModalHandler(row._id, row)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <TablePagination
          rowsPerPageOptions={[100, 150, 200, 500]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={isPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
      {PendingDues} {/* Interpreter Dues modal */}
      <AddClientPayment
        open={isOpen}
        handleClose={closePaymentModal}
        isClients={isClients}
        getClientsHandler={getClientsHandler}
        approvedBy={userData.user}
        submitHandler={addClientAllocatePaymentHandler}
      />
    </React.Fragment>
  );
};
