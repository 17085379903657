import React, { useEffect, useContext, useState, useCallback } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";

import {
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TableBody,
  TablePagination,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import { EnhancedTableHead } from "./EnhancedTableHead";
import { lighten, makeStyles } from "@material-ui/core/styles";

import VisibilityIcon from "@material-ui/icons/Visibility";

import { RemittanceContext } from "../../../../context/states/remittanceState";
import { TablePaginationActions } from "../../../UI/TablePaginationActions";
import { ViewDetails } from "./viewDetails/ViewDetails";
import { getComparator, stableSort } from "../../../../libs/headerSortMethods";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  container: {
    height: "calc(70vh - 20px)",
  },
  container2: {
    height: "calc(80vh - 20px)",
  },
  table: {
    // minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  countSpan: {
    backgroundColor: lighten(theme.palette.secondary.dark, 0.4),
    padding: "3px 7px",
    borderRadius: "2px",
    color: "#fff",
    fontSize: "10px",
    fontWeight: 600,
  },
}));

const headCells = [
  { id: "slno", numeric: false, disablePadding: false, label: "Sl.No" },
  { id: "remittanceRef", numeric: false, disablePadding: false, label: "ID" },
  { id: "details", numeric: false, disablePadding: false, label: "Total Jobs" },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: false,
    label: "Amount Paid",
  },
  {
    id: "paymentDate",
    numeric: true,
    disablePadding: false,
    label: "Payment Date",
  },
  {
    id: "approvedBy",
    numeric: true,
    disablePadding: false,
    label: "Approved By",
  },
  { id: "view", numeric: false, disablePadding: false, label: "View" },
];

const initialState = {
  interprter: "",
  isSearch: false,
  isPage: 1,
  rowsPerPage: 50,
  fromDate: null,
  toDate: null,
};

export const RemittanceLists = (props) => {
  const classes = useStyles();
  const { id: intID } = useParams();
  const { title, adminUser } = props;

  const {
    loading,
    getRemittancesHandler,
    data,
    total,
    getInterpreterRemittancesHandler,
    interpreters,
    getInterpretersHandler,
    totalAmount,
  } = useContext(RemittanceContext);
  const matches = useMediaQuery("(min-width:1920px)");

  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("remittanceRef");
  const [dense, setDense] = useState(true);
  const [isPage, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(500);
  const [isViewModal, setIsViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isData, setIsData] = useState({});

  const [values, setValues] = useState({ ...initialState });

  useEffect(() => {
    const query =
      isPage !== 0
        ? `?page=${isPage + 1}&limit=${rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;
    if (!adminUser) {
      // Individual Interpreter list inside interpreter route
      const id = localStorage.getItem("loggedIn");
      getInterpreterRemittancesHandler(intID === undefined ? id : intID, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, isPage, localStorage.getItem("loggedIn")]);

  useEffect(() => {
    const query =
      isPage !== 0
        ? `?page=${values.isPage}&limit=${values.rowsPerPage}`
        : `?page=1&limit=${rowsPerPage}`;
    if (adminUser) {
      // Interpreter remittance list
      getRemittancesHandler(query);
    }
  }, [values.isPage, values.rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setValues({ ...values, isPage: newPage + 1 });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setValues({ ...values, rowsPerPage: parseInt(event.target.value, 10) });
    setPage(0);
  };

  // ViewDetails model
  // console.log(isData);
  const closeViewModalHandler = useCallback(() => setIsViewModal(false), []);
  const Views = React.useMemo(() => {
    return (
      <ViewDetails
        open={isViewModal}
        handleClose={closeViewModalHandler}
        isloading={isLoading}
        data={isData}
      />
    );
  }, [closeViewModalHandler, isLoading, isData, isViewModal]);
  const openViewModalHandler = useCallback(async (row) => {
    setIsLoading(true);
    try {
      await setIsLoading(false);
      await setIsData(row);
      await setIsViewModal(true);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeAutoComplete = (name) => (event, value) => {
    console.log("event", name, value);
    if (value === null) {
      setValues({
        ...initialState,
        isSearch: false,
        isPage: 1,
        rowsPerPage: 50,
      });
      // clearStateHandler()
      return;
    }
    if (name === "interprter") {
      setValues({ ...values, [name]: value, interprterId: value._id });
    }
  };

  const handleInputChange = (event) => {
    if (event.target.value === "") return;
    getInterpretersHandler(`name/${event.target.value}`);
  };

  const handleFromDateChange = (event) => {
    const date = new Date(event).toISOString();
    console.log("date", date);
    setValues({ ...values, fromDate: date });
  };

  const handleToDateChange = (event) => {
    const date = new Date(event).toISOString();
    console.log("date", date);
    setValues({ ...values, toDate: date });
  };

  const searchHandler = () => {
    setValues({ ...values, isSearch: true });
    let filteredDate = `?`;
    if (values.interprter._id === undefined) {
      filteredDate += ``;
    } else {
      // setValues({ ...values, isSearch: true })
      filteredDate += `interpreterId=${values.interprter._id}`;
    }

    if (values.fromDate === null) {
      // filteredDate = '?'
      // setValues({ ...values, isSearch: false })
    } else if (values.fromDate !== null) {
      filteredDate += `&fromDate=${values.fromDate}`;
      if (values.toDate !== null) {
        console.log("to date", values.toDate);
        filteredDate += `&toDate=${values.toDate}`;
      }
    }

    getRemittancesHandler(`${filteredDate}`);
  };

  const clearSearchHandler = () => {
    setValues({ ...initialState });
    getRemittancesHandler(`?page=${values.isPage}&limit=${values.rowsPerPage}`);
  };

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {!intID && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            handleOpen={() => {}}
            title={title}
            totalAmount={totalAmount}
            handleChangeAutoComplete={handleChangeAutoComplete}
            interpreters={interpreters}
            values={values}
            handleInputChange={handleInputChange}
            handleFromDateChange={handleFromDateChange}
            handleToDateChange={handleToDateChange}
            searchHandler={searchHandler}
            clearSearchHandler={clearSearchHandler}
          />
        )}
      </MuiPickersUtilsProvider>
      <TableContainer
        className={matches ? classes.container2 : classes.container}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          className={classes.table}
          size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center" }}
                  rowSpan={6}
                  colSpan={7}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : data.length === 0 ? (
              <TableRow hover>
                <TableCell
                  style={{ textAlign: "center" }}
                  rowSpan={6}
                  colSpan={7}
                >
                  No record
                </TableCell>
              </TableRow>
            ) : (
              stableSort(data, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={row._id}>
                      <TableCell></TableCell>
                      <TableCell align="left">
                        {index + 1 + isPage * rowsPerPage}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.remittanceRef}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {adminUser && row.interpreterName}{" "}
                        <span className={classes.countSpan}>
                          {row.details.length}
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        {row.totalAmount?.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {moment(row.paymentDate).format("DD-MM-y")}
                      </TableCell>
                      <TableCell align="right">{row.approvedBy}</TableCell>
                      <TableCell align="left">
                        <Tooltip title={`Remittance No: ${row.remittanceRef}`}>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => openViewModalHandler(row)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && !values.isSearch && (
        <TablePagination
          rowsPerPageOptions={[100, 150, 200, 500]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={isPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      )}
      {Views}
    </React.Fragment>
  );
};
