import React, { createContext, useReducer } from "react";
import OutstandingDueReducer from "../reducers/outstandingDuesReducer";
import axios from "../../axios";

import * as actionTypes from "../actionTypes";
import * as urlRequest from "../urls";

const initialState = {
  loading: false,
  load: false,
  outStandings: [],
  due: {},
  submitteData: {},
  total: 0,
  count: 0,
  clients: [],
  totalAmount: 0,
  totalCredit: 0,
};

export const OutstandingDueContext = createContext(initialState);
export const OutstandingDueProvider = ({ children }) => {
  const [state, dispatch] = useReducer(OutstandingDueReducer, initialState);

  const getOutstandingDuesHandler = async (query) => {
    dispatch({ type: actionTypes.PROCESS_START });
    try {
      const result = await axios.get(urlRequest.OutstandingOverCPR + query);
      dispatch({ type: actionTypes.FETCH_RECORDS, payload: result.data });
    } catch (error) {
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  const getOutstandingDueHandler = async (id) => {
    dispatch({ type: "LOAD_DUE" });
    try {
      const result = await axios.get(`${urlRequest.OutstandingOverCPR}/${id}`);
      dispatch({ type: actionTypes.FETCH_RECORD, payload: result.data.data });
    } catch (error) {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_ERRORS, payload: error });
    }
  };

  // const addClientPaymentHandler = async (data) => {
  //   const result = await axios.post(urlRequest.ClientPayment, data);
  //   dispatch({ type: actionTypes.ADD_RECORD, payload: result.data });
  //   if (result.data.success) {
  //     // alertDispatch({ type: 'open', message: 'Interpreter created' });
  //   }
  //   return await result;
  // };

  const makePaymentHandler = async (data) => {
    const result = await axios.post(
      urlRequest.CreateClientPayment + "/" + data.id,
      data
    );
    // dispatch({ type: actionTypes.ADD_RECORD, payload: result.data });
    if (result.data.success) {
      // alertDispatch({ type: 'open', message: 'Interpreter created' });
    }
    return await result;
  };

  const getClientsHandler = async (company) => {
    try {
      const result = await axios.get(`/api/v1/clients?companyName=${company}`);
      dispatch({ type: "FETCH_CLIENT_RECORD", payload: result.data });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <OutstandingDueContext.Provider
      value={{
        loading: state.loading,
        data: state.outStandings,
        total: state.total,
        load: state.load,
        dues: state.due,
        totalAmount: state.totalAmount,
        totalCredit: state.totalCredit,
        submitteData: state.submitteData,
        getOutstandingDuesHandler,
        getOutstandingDueHandler,
        // addClientPaymentHandler,
        makePaymentHandler,
        clients: state.clients,
        getClientsHandler,
      }}
    >
      {children}
    </OutstandingDueContext.Provider>
  );
};
