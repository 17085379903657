import React from "react";

import { RemittanceProvider } from "../../../context/states/remittanceState";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { RemittanceLists } from "../../../components/pages/payments/iRemittance/RemittanceLists";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
}));

const RemittanceSlips = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <RemittanceProvider>
        <div classes={classes.root}>
          <Paper className={classes.paper}>
            <RemittanceLists title="Remittance Slips" adminUser={true} />
          </Paper>
        </div>
      </RemittanceProvider>
    </React.Fragment>
  );
};

export default RemittanceSlips;
