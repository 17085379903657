import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useForm, Controller } from "react-hook-form";
// import { DevTool } from '@hookform/devtools';

import { FileContext } from "../../../../../context/states/fileState";
import { FileUploads } from "../../../../fileUpload/UploadFiles/FileUploads";
import { ViewFile } from "../../../../fileUpload/ViewFile";

import {
  Grid,
  CardActions,
  AppBar,
  Toolbar,
  Typography,
  Paper,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  // IconButton,
  // InputBase,
  Tooltip,
} from "@material-ui/core";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DeleteIcon from "@material-ui/icons/Delete";

// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';

import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardTimePicker, KeyboardDatePicker } from "@material-ui/pickers";

import { BookingContext } from "../../../../../context/states/bookingState";
import { RateContext } from "../../../../../context/states/rateState";

import Loader from "../../../../UI/Loader";
import { PrepareQuotation } from "./PrepareQuotation/PrepareQuotation";
import { FileLists } from "./FileList";
export const EditForm = React.memo((props) => {
  const { booking, classes, handleUpdate, handleBack } = props;
  const {
    getLangugagesHandler,
    getDialectsHandler,
    isLanguages,
    isDialects,
    fileList,
    getDocumentHandler,
    viewDoc,
    isInterpreters,
    getInterpreterHandler,
    isRateStructures,
    getRateStructuresHandler,
    getClientsHandler,
    isClients,
    isCaseTypes,
    isDepartments,
  } = useContext(BookingContext);
  const {
    handleFiles,
    unsupportedFiles,
    validFiles,
    uploadFiles,
    removeFile,
    percentage,
    message,
    bookingDocumentDeleteHandler,
  } = useContext(FileContext);
  const { getRateHandler, isRate, getMinRatesHandler, isMinRates } =
    useContext(RateContext);

  // Backdrop loader
  const [isLoading, setIsLoading] = useState(false);
  const backdropCloseHandler = () => setIsLoading(false);

  // upload button
  const [isOpen, setIsOpen] = useState(false);
  const handleClickOpen = () => setIsOpen(true);
  const handleModalClose = () => setIsOpen(false);

  // view modal button
  const [isModal, setIsModal] = useState(false);
  const toggleModal = () => setIsModal(false);

  const openImageModal = useCallback(
    (file) => {
      getDocumentHandler("getdoc", file);
      setIsModal(true);
    },
    [getDocumentHandler]
  );

  const formValue = {
    _id: booking._id,
    isBooked: booking.isBooked,
    bookingType: booking.bookingType,
    businessType: booking.businessType,
    bookingRef: booking.booking_ref,
    serviceType: booking.serviceType,
    serviceChargePercent: booking.serviceChargePercent,
    department: booking.department,
    companyName: booking.companyName,
    companyId: booking.companyId,
    location: booking.location,
    name: booking.name,
    phone: booking.phone,
    mobile: booking.mobile,
    address: booking.address,
    invoiceAddress: booking.invoiceAddress,
    paymentMethod: booking.paymentMethod,
    language: booking.language,
    dialect: booking.dialect,
    email: booking.email,
    invoiceTo: booking.invoiceTo,
    clientName: booking.clientName,
    fileRef: booking.fileRef,
    notes: booking.notes,
    gender: booking.gender,
    caseType: booking.caseType,
    purposeOfCall: booking.purposeOfCall,
    date: booking.date,
    startTime: booking.startTime,
    endTime: booking.endTime,
    place: booking.place, // place label is place of attendance
    personAttending: booking.personAttending,
    postcode: booking.postcode,
    placeOfAttendance: booking.placeOfAttendance, // placeOfAttendance label is address
    otherReference: booking.otherReference,
    documentType: booking.documentType,
    formatType: booking.formatType,
    files: booking.files,
    urgency: booking.urgency,
    transcriptionType: booking.transcriptionType,
    urlLink: booking.urlLink,
    interpreter: booking.interpreter,
    interpreterName: booking.interpreterName,
    billingRate: booking.billingRate,
    billingRateId: booking.billingRateId,
    allocateCompany: booking.allocateCompany,
    isSendQuote: booking.isSendQuote,
    advancePayment: booking.advancePayment,
    status: booking.status,
    quotation: booking.quotation,
    pageCount: booking.pageCount,
    wordCount: booking.wordCount,
    durationMin: booking.durationMin,
    durationHour: booking.durationHour,
    attendanceOne: booking.attendanceOne,
    attendanceTwo: booking.attendanceTwo,
    estimatedTravelIn: booking.estimatedTravelIn,
    estimatedTravelOut: booking.estimatedTravelOut,
    estimatedFares: booking.estimatedFares,
    estimatedMileage: booking.estimatedMileage,
    estimatedWaiting: booking.estimatedWaiting,
    parkingFee: booking.parkingFee,
    previewBtn: booking?.previewBtn,
    vat: booking?.vat,
    isSplitInvoice: booking?.isSplitInvoice,
  };

  // console.log(formValue.startTime, formValue.endTime);

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    formState,
  } = useForm({
    mode: "onChange",
    defaultValues: formValue,
  });

  const { isDirty, isValid } = formState;

  const handleFromChange = useCallback(async (event, value) => {
    const query = value;
    await getClientsHandler(query);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    /*******  
     console.log(query);
      console.log(isClients);
      if (!value || value?.length < 3) {
        if (fromOptions.length) {
          dispatch({
            type: SET_FROM_OPTIONS_DELIVERY,
            payload: [],
          });
        }
      } else {
        const query = {
          params: {
            query: value,
          },
        };

        const { data } = await axios.get(api.GET.GET_OPTIONS, query);
        dispatch({
          type: SET_FROM_OPTIONS_DELIVERY,
          payload: data,
        });
      } */
  }, []);

  const buT = watch("businessType");
  // const boS = watch('status');
  const sT = watch("serviceType");
  const showUrlLink = watch("formatType");
  const allocateCom = watch("allocateCompany");

  // const interpreterNameWatch = watch('interpreterName');
  const billingRateWatch = watch("billingRate");
  // const previewBtnWatch = watch('previewBtn');

  // const handleRePrepareChange = () => setIsUpdated(!isUpdated);
  // const [isUpdated, setIsUpdated] = useState(false);

  // console.log(previewBtnWatch);
  const onSubmit = async (data) => {
    setIsLoading(true);
    // previewBtn condition
    // billingRateCheck && interpreterNameCheck && (boS === 'Booking' || boS === 'Quotation') ? (data.previewBtn = true) : (data.previewBtn = false);

    try {
      // console.log(data);
      await handleUpdate(data);
      await backdropCloseHandler();
      // setIsUpdated(!isUpdated);
    } catch (error) {
      console.log(error);
      await backdropCloseHandler();
    }
  };

  const quotationOnSubmit = useCallback(
    async (quotation) => {
      try {
        await handleUpdate(quotation);
        // console.log(quotation);
      } catch (error) {
        console.log(error);
      }
    },
    [handleUpdate]
  );

  const [isQForm, setIsQForm] = useState(false);
  const quotationFormBtn = useCallback(() => {
    setIsQForm(!isQForm);
    getMinRatesHandler();
  }, [isQForm, setIsQForm, getMinRatesHandler]);

  // const checkThisSelect = Object.values(isRateStructures);

  // const interpreterNameCheck = interpreterNameWatch !== undefined ? (interpreterNameWatch.length !== 0 ? true : false) : null;
  // const billingRateCheck = billingRateWatch !== undefined ? (billingRateWatch !== (checkThisSelect[0] !== undefined ? checkThisSelect[0]._id : '') ? true : false) : null;

  useEffect(() => {
    console.log("billingRateWatch", billingRateWatch);
    let unmounted = false;
    if (billingRateWatch) {
      if (!unmounted) {
        getRateHandler(billingRateWatch);
        // getMinRatesHandler();
      }
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQForm]);

  let serviceTitle = "";

  if (sT === "Telephone") {
    serviceTitle = "Telephone";
  } else if (sT === "Video Call") {
    serviceTitle = "Video Call";
  } else if (sT === "Onsite") {
    serviceTitle = "Onsite";
  } else if (sT === "Translation") {
    serviceTitle = "Translations of Document(s) ";
  } else if (sT === "Transcription") {
    serviceTitle = "Transcription";
  } else {
    serviceTitle = "No Title";
  }

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loaded = open && options.length === 0;

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    let active = true;
    getRateStructuresHandler();

    if (active) {
      getLangugagesHandler();
      getDialectsHandler();
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (e, targetName) => {
      setValue(
        targetName ? targetName : e.target.name,
        (e && e.target && e.target.value) || e,
        {
          shouldDirty: true,
        }
      );
    },
    [setValue]
  );

  // interpreter name onchange handler
  const handleInterpreterChange = useCallback(
    (e, targetName) => {
      setValue(
        targetName ? targetName : e.target.name,
        (e && e.target && e.target.value) || e,
        {
          shouldDirty: true,
        }
      );
    },
    [setValue]
  );

  // interpreter id onchange handler
  const handleInterpreterIdChange = useCallback(
    (e, targetName) => {
      setValue(
        targetName ? targetName : e.target.name,
        (e && e.target && e.target.value) || e
      );
    },
    [setValue]
  );

  // interpreter autocomplete fetch api
  const onInputChange = useCallback(
    (event, value) => {
      console.log("re-render int value");
      getInterpreterHandler(`name/${value}`);
    },
    [getInterpreterHandler]
  );

  const quotationForm = useMemo(
    () => (
      <PrepareQuotation
        open={isQForm}
        handleClose={quotationFormBtn}
        classes={classes}
        data={formValue}
        isRate={isRate}
        isMinRates={isMinRates}
        onSubmitHandler={quotationOnSubmit}
      />
    ),
    [
      isQForm,
      quotationFormBtn,
      formValue,
      isRate,
      isMinRates,
      classes,
      quotationOnSubmit,
    ]
  );

  const documentDeleteHandler = useCallback(
    (file) => {
      const data = { id: formValue?._id, filename: file };
      bookingDocumentDeleteHandler(data);
    },
    [formValue]
  );

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        {/* <DevTool control={control} /> */}
        <input
          ref={register({ required: true })}
          name="_id"
          style={{ display: "none" }}
        />
        <Paper className={classes.paper} variant="outlined">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="quotation-booking-label">
                  Quotation / Booking
                </InputLabel>
                <Controller
                  as={
                    <Select
                      label="Quotation / Booking"
                      inputProps={{
                        name: "quotation",
                        id: "quotation-booking-label",
                      }}
                    >
                      <MenuItem aria-label="None" value="" />
                      <MenuItem value={"Quotation"}>Quotation</MenuItem>
                      <MenuItem value={"Booking"}>Booking</MenuItem>
                    </Select>
                  }
                  control={control}
                  name="bookingType"
                  rules={{ required: true }}
                  error={!!errors.bookingType}
                  defaultValue=""
                />
              </FormControl>
            </Grid>

            {buT !== "Private" && (
              <React.Fragment>
                <Grid item xs={12} sm={6} md={3}>
                  {!allocateCom ? (
                    <TextField
                      id="company-name"
                      label="Company Name"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="companyName"
                      inputRef={register({
                        required: true,
                        minLength: {
                          value: 2,
                          message: "Must have at least 2 letters",
                        },
                        maxLength: {
                          value: 100,
                          message: "Maximum characters limit is 100",
                        },
                      })}
                      error={!!errors.companyName}
                      helperText={
                        errors.companyName && errors.companyName.message
                      }
                      defaultValue=""
                      disabled={!allocateCom}
                    />
                  ) : (
                    <>
                      <Autocomplete
                        options={isClients}
                        onInputChange={(event, value) =>
                          handleFromChange(event, value)
                        }
                        onChange={(_, value) => {
                          handleChange(
                            value !== null ? value._id : "",
                            "companyId"
                          );
                          handleChange(
                            value !== null ? value.companyName : "",
                            "companyName"
                          );
                        }}
                        getOptionLabel={(option) => option.companyName}
                        getOptionSelected={(option, value) =>
                          option.companyName === value.companyName
                        }
                        defaultValue={{
                          companyName:
                            booking.companyName !== undefined
                              ? booking.companyName
                              : "",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company Name"
                            name="companyName"
                            size="small"
                            fullWidth
                            variant="outlined"
                            error={!!errors.companyId}
                          />
                        )}
                      />

                      <input
                        style={{ display: "none" }}
                        name="companyName"
                        ref={register({ required: true })}
                      />
                      <input
                        style={{ display: "none" }}
                        name="companyId"
                        ref={register({ required: true })}
                        defaultValue=""
                      />
                    </>
                  )}
                </Grid>
                <Grid item md={1}>
                  <Tooltip title="Allocate Company">
                    <FormControlLabel
                      inputRef={register}
                      control={
                        <Checkbox
                          name="allocateCompany"
                          color="primary"
                          defaultChecked={
                            booking !== undefined
                              ? booking.allocateCompany
                              : false
                          }
                        />
                      }
                      label="Allocate"
                    />
                  </Tooltip>
                </Grid>
              </React.Fragment>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="name"
                label="Name"
                size="small"
                fullWidth
                variant="outlined"
                name="name"
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 2,
                    message: "Must have at least 2 letters",
                  },
                  maxLength: {
                    value: 40,
                    message: "Maximum characters limit is 40",
                  },
                })}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="department-label">Department</InputLabel>
                <Controller
                  as={
                    <Select
                      label="Department"
                      inputProps={{
                        name: "service",
                        id: "department-label",
                      }}
                    >
                      {isDepartments.map((department) => (
                        <MenuItem key={department._id} value={department.name}>
                          {department.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  control={control}
                  name="department"
                  rules={{ required: true }}
                  error={!!errors.department}
                  defaultValue={""}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="phone"
                label="Phone"
                size="small"
                fullWidth
                variant="outlined"
                name="phone"
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid phone number",
                  },
                  minLength: {
                    value: 10,
                    message: "Must have at least 10 digits",
                  },
                  maxLength: {
                    value: 15,
                    message: "Maximum digits limit is 15",
                  },
                })}
                error={!!errors.phone}
                helperText={errors.phone && errors.phone.message}
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="mobile"
                label="Mobile"
                size="small"
                fullWidth
                variant="outlined"
                name="mobile"
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 10,
                    message: "Must have at least 10 digits",
                  },
                  maxLength: {
                    value: 15,
                    message: "Maximum digits limit is 15",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid mobile number",
                  },
                })}
                error={!!errors.mobile}
                helperText={errors.mobile && errors.mobile.message}
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="address"
                label="Address"
                size="small"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                name="address"
                inputRef={register({
                  required: true,
                  minLength: {
                    value: 5,
                    message: "Must have at least 5 letters",
                  },
                  maxLength: {
                    value: 150,
                    message: "Maximum characters limit is 150",
                  },
                })}
                error={!!errors.address}
                helperText={errors.address && errors.address.message}
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <TextField
                id="invoiceAddress"
                label="Invoice Address"
                size="small"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                name="invoiceAddress"
                inputRef={register({
                  required: false,
                  minLength: {
                    value: 5,
                    message: "Must have at least 5 letters",
                  },
                  maxLength: {
                    value: 150,
                    message: "Maximum characters limit is 150",
                  },
                })}
                error={!!errors.invoiceAddress}
                helperText={
                  errors.invoiceAddress && errors.invoiceAddress.message
                }
                defaultValue=""
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend" error={!!errors.paymentMethod}>
                  Payment Method
                </FormLabel>

                <RadioGroup
                  name="paymentMethod"
                  row
                  defaultValue={
                    booking.paymentMethod !== undefined
                      ? booking.paymentMethod
                      : ""
                  }
                >
                  <FormControlLabel
                    value="BACS"
                    inputRef={register}
                    control={<Radio color="primary" />}
                    label="By BACS Transfer"
                  />
                  <FormControlLabel
                    value="Card"
                    inputRef={register}
                    control={<Radio color="primary" />}
                    label="By Debit / Credit Card"
                  />
                  <FormControlLabel
                    value="Invoice"
                    inputRef={register}
                    control={<Radio color="primary" />}
                    label="By Invoice"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                options={isLanguages}
                filterSelectedOptions
                onChange={(e, value) =>
                  handleChange(value !== null ? value.language : "", "language")
                }
                getOptionLabel={(option) => option.language}
                getOptionSelected={(option, value) =>
                  option.language === value.language
                }
                defaultValue={{
                  language:
                    booking.language !== undefined ? booking.language : "",
                }}
                renderOption={(option) => <>{option.language}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a language"
                    size="small"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="language"
                    error={!!errors.language}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "disabled",
                    }}
                  />
                )}
              />
              <input
                style={{ display: "none" }}
                name="language"
                ref={register({ required: true })}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                options={isDialects}
                filterSelectedOptions
                onChange={(e, value) =>
                  handleChange(value !== null ? value.dialect : "", "dialect")
                }
                getOptionLabel={(option) => option.dialect}
                getOptionSelected={(option, value) =>
                  option.dialect === value.dialect
                }
                defaultValue={{
                  dialect: booking.dialect !== undefined ? booking.dialect : "",
                }}
                renderOption={(option) => <>{option.dialect}</>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a dialect"
                    size="small"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="dialect"
                    error={!!errors.dialect}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "disabled",
                    }}
                  />
                )}
              />
              <input
                style={{ display: "none" }}
                name="dialect"
                ref={register({ required: false })}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <FormControl component="fieldset">
                <FormLabel component="legend" error={!!errors.location}>
                  Location
                </FormLabel>
                <RadioGroup
                  name="location"
                  row
                  defaultValue={
                    booking.location !== undefined ? booking.location : ""
                  }
                >
                  <FormControlLabel
                    value="In London"
                    inputRef={register}
                    control={<Radio color="primary" />}
                    label="In London"
                  />
                  <FormControlLabel
                    value="Out of London"
                    inputRef={register}
                    control={<Radio color="primary" />}
                    label="Out of London"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Status */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel htmlFor="status-label">Status</InputLabel>
                <Controller
                  as={
                    <Select
                      label="Status"
                      inputProps={{
                        name: "status",
                        id: "status-label",
                      }}
                    >
                      <MenuItem aria-label="None" value="" />
                      <MenuItem value={"Booking"}>Booking</MenuItem>
                      <MenuItem value={"Quotation"}>Quotation</MenuItem>
                      <MenuItem value={"Confirmed"}>Confirmed</MenuItem>
                      <MenuItem value={"Attended but no timesheet"}>
                        Attended but no timesheet
                      </MenuItem>
                      <MenuItem value={"Timesheet Submitted"}>
                        Timesheet Submitted
                      </MenuItem>
                      <MenuItem value={"Approved"}>Approved</MenuItem>
                      <MenuItem value={"Paid"}>Paid</MenuItem>
                      <MenuItem value={"Quote Supplied"}>
                        Quote Supplied
                      </MenuItem>
                      <MenuItem value={"Company Outstanding"}>
                        Company Outstanding
                      </MenuItem>
                      <MenuItem value={"Cancel"}>Cancel</MenuItem>
                    </Select>
                  }
                  control={control}
                  name="status"
                  rules={{ required: true }}
                  error={!!errors.status}
                  defaultValue=""
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Advance Payment"
                size="small"
                fullWidth
                variant="outlined"
                name="advancePayment"
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^[0-9]*(\.[0-9]{0,2})?$/,
                    message: "Amount",
                  },
                })}
                error={!!errors.advancePayment}
                helperText={
                  errors.advancePayment && errors.advancePayment.message
                }
                defaultValue="0"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label={booking?.vat ? "VAT %" : "Service Charge %"}
                size="small"
                fullWidth
                variant="outlined"
                name="serviceChargePercent"
                InputProps={{
                  readOnly: true,
                }}
                inputRef={register({
                  required: true,
                  pattern: {
                    value: /^[0-9]*(\.[0-9]{0,2})?$/,
                    message: "Amount",
                  },
                })}
                error={!!errors.serviceChargePercent}
                helperText={
                  errors.serviceChargePercent &&
                  errors.serviceChargePercent.message
                }
                defaultValue=""
              />
            </Grid>
          </Grid>
        </Paper>

        {sT === "" ? null : (
          <React.Fragment>
            <Paper className={classes.paper} variant="outlined">
              {/* {interpretingList} */}
              <AppBar
                position="static"
                elevation={0}
                className={classes.appBar}
              >
                <Toolbar variant="dense">
                  <Typography variant="h6" className={classes.title}>
                    {serviceTitle}
                  </Typography>
                  {/* <Tooltip title="Assign Job">
                    <IconButton color="inherit" onClick={quotationFormBtn}>
                      <AssignmentIndIcon />
                    </IconButton>
                  </Tooltip> */}
                </Toolbar>
              </AppBar>

              <React.Fragment>
                <Grid container spacing={2}>
                  {/* Telephone and Video call */}
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      id="email"
                      label="Email"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="email"
                      inputRef={register({
                        required: true,
                        minLength: {
                          value: 5,
                          message: "Must have at least 5 letters",
                        },
                        maxLength: {
                          value: 60,
                          message: "Maximum characters limit is 60",
                        },
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email-id",
                        },
                      })}
                      error={!!errors.email}
                      helperText={errors.email && errors.email.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      id="invoiceTo"
                      label="Invoice To"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="invoiceTo"
                      inputRef={register({
                        required: false,
                        minLength: {
                          value: 5,
                          message: "Must have at least 5 letters",
                        },
                        maxLength: {
                          value: 60,
                          message: "Maximum characters limit is 60",
                        },
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email-id",
                        },
                      })}
                      error={!!errors.invoiceTo}
                      helperText={errors.invoiceTo && errors.invoiceTo.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      id="clientName"
                      label="Client Name"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="clientName"
                      inputRef={register({
                        required: true,
                        minLength: {
                          value: 2,
                          message: "Must have at least 2 letters",
                        },
                        maxLength: {
                          value: 40,
                          message: "Maximum characters limit is 40",
                        },
                      })}
                      error={!!errors.clientName}
                      helperText={
                        errors.clientName && errors.clientName.message
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      id="fileRef"
                      label="File / Invoice Reference"
                      size="small"
                      fullWidth
                      variant="outlined"
                      name="fileRef"
                      inputRef={register({
                        required: true,
                        minLength: {
                          value: 2,
                          message: "Must have at least 2 letters",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maximum characters limit is 50",
                        },
                      })}
                      error={!!errors.fileRef}
                      helperText={errors.fileRef && errors.fileRef.message}
                    />
                  </Grid>
                  {sT === "Telephone" || sT === "Video Call" ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="gender-label">
                            Interpreter Gender
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Interpreter Gender"
                                inputProps={{
                                  name: "gender",
                                  id: "gender-label",
                                }}
                              >
                                <MenuItem aria-label="None" value="" />
                                <MenuItem value={"male"}>Male</MenuItem>
                                <MenuItem value={"female"}>Female</MenuItem>
                                <MenuItem value={"any"}>Any</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="gender"
                            rules={{ required: true }}
                            error={!!errors.gender}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="purpose-call-label">
                            Purpose of Call
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Purpose of Call"
                                inputProps={{
                                  name: "purpose-call",
                                  id: "purpose-call-label",
                                }}
                              >
                                <MenuItem aria-label="None" value="" />
                                <MenuItem value={"Convey a message"}>
                                  Convey a Message
                                </MenuItem>
                                <MenuItem value={"Translate a statement"}>
                                  Translate a statement
                                </MenuItem>
                                <MenuItem value={"Take a statement"}>
                                  Take a statement
                                </MenuItem>
                                <MenuItem value={"Medical Assessment"}>
                                  Medical assessment
                                </MenuItem>
                                <MenuItem value={"Court Hearing"}>
                                  Court Hearing
                                </MenuItem>
                                <MenuItem value={"Surgery call"}>
                                  Surgery call
                                </MenuItem>
                                <MenuItem value={"Explain the legal procedure"}>
                                  Explain the legal procedure
                                </MenuItem>
                                <MenuItem value={"Pre-hearing Consultation"}>
                                  Pre-hearing Consultation
                                </MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="purposeOfCall"
                            rules={{ required: true }}
                            error={!!errors.purposeOfCall}
                          />
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  {buT !== "Private" && (
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl variant="outlined" size="small" fullWidth>
                        <InputLabel htmlFor="case-type-label">
                          Case / Matter Type
                        </InputLabel>
                        <Controller
                          as={
                            <Select
                              label="Case / Matter Type"
                              inputProps={{
                                name: "case-type",
                                id: "case-type-label",
                              }}
                            >
                              {/* <MenuItem aria-label="None" value="" />
                              <MenuItem value={'Legal Aid'}>Legal Aid</MenuItem>
                              <MenuItem value={'Certificated'}>Certificated</MenuItem>
                              <MenuItem value={'Crime'}>Crime</MenuItem>
                              <MenuItem value={'Housing'}>Housing</MenuItem>
                              <MenuItem value={'Private'}>Private</MenuItem>
                              <MenuItem value={'Other'}>Other</MenuItem> */}
                              {isCaseTypes.map((ct) => (
                                <MenuItem key={ct._id} value={ct.name}>
                                  {ct.name}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                          control={control}
                          name="caseType"
                          rules={{ required: true }}
                          error={!!errors.caseType}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  {sT === "Onsite" ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="gender-label">
                            Interpreter Gender
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Interpreter Gender"
                                inputProps={{
                                  name: "gender",
                                  id: "gender-label",
                                }}
                              >
                                <MenuItem aria-label="None" value="" />
                                <MenuItem value={"male"}>Male</MenuItem>
                                <MenuItem value={"female"}>Female</MenuItem>
                                <MenuItem value={"any"}>Any</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="gender"
                            rules={{ required: true }}
                            error={!!errors.gender}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="place-label">
                            Place of Attendance
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Place of Attendance"
                                inputProps={{
                                  name: "place",
                                  id: "place-label",
                                }}
                              >
                                <MenuItem aria-label="None" value="" />
                                <MenuItem value={"Court"}>Court</MenuItem>
                                <MenuItem value={"Detention Centre"}>
                                  Detention Center
                                </MenuItem>
                                <MenuItem value={"Prison Centre"}>
                                  Prison Centre
                                </MenuItem>
                                <MenuItem value={"Ukba Offices"}>
                                  UKBA Offices
                                </MenuItem>
                                <MenuItem value={"Office"}>Office</MenuItem>
                                <MenuItem value={"Hospital"}>Hospital</MenuItem>
                                <MenuItem value={"Private Residence"}>
                                  Private Residence
                                </MenuItem>
                                <MenuItem value={"School"}>School</MenuItem>
                                <MenuItem value={"GP Surgery"}>
                                  GP Surgery
                                </MenuItem>
                                <MenuItem value={"Chambers"}>Chambers</MenuItem>
                                <MenuItem value={"Police station"}>
                                  Police Station
                                </MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="place"
                            rules={{ required: true }}
                            error={!!errors.place}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="personAttending"
                          label="Person Attending"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="personAttending"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 2,
                              message: "Must have at least 2 letters",
                            },
                            maxLength: {
                              value: 40,
                              message: "Maximum characters limit is 40",
                            },
                          })}
                          error={!!errors.personAttending}
                          helperText={
                            errors.personAttending &&
                            errors.personAttending.message
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          id="attendancePostcode"
                          label="Attendance Postcode"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="postcode"
                          inputRef={register({
                            required: true,
                            pattern: {
                              value:
                                /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
                              message: "Invalid postcode",
                            },
                          })}
                          error={!!errors.postcode}
                          helperText={
                            errors.postcode && errors.postcode.message
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="placeOfAttendance"
                          label="Address"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="placeOfAttendance"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 2,
                              message: "Must have at least 2 letters",
                            },
                            maxLength: {
                              value: 200,
                              message: "Maximum characters limit is 200",
                            },
                          })}
                          error={!!errors.placeOfAttendance}
                          helperText={
                            errors.placeOfAttendance &&
                            errors.placeOfAttendance.message
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="otherReference"
                          label="Other Reference"
                          size="small"
                          fullWidth
                          variant="outlined"
                          name="otherReference"
                          inputRef={register({
                            required: true,
                            minLength: {
                              value: 2,
                              message: "Must have at least 2 letters",
                            },
                            maxLength: {
                              value: 150,
                              message: "Maximum characters limit is 150",
                            },
                          })}
                          error={!!errors.otherReference}
                          helperText={
                            errors.otherReference &&
                            errors.otherReference.message
                          }
                        />
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  {/* Onsite */}
                  {/* Translations */}
                  {sT === "Translation" ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="document-type-label">
                            Type of Document
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Type of Document"
                                inputProps={{
                                  name: "document-type",
                                  id: "document-type-label",
                                }}
                              >
                                <MenuItem aria-label="None" value="" />
                                <MenuItem value={"Legal"}>Legal</MenuItem>
                                <MenuItem value={"Housing"}>Housing</MenuItem>
                                <MenuItem value={"Personal documents"}>
                                  Personal Documents
                                </MenuItem>
                                <MenuItem value={"IDs"}>IDs</MenuItem>
                                <MenuItem value={"Medical"}>Medical</MenuItem>
                                <MenuItem value={"Statement"}>
                                  Statement
                                </MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="documentType"
                            rules={{ required: true }}
                            error={!!errors.documentType}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="format-type-label">
                            Format Type
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Format Type"
                                inputProps={{
                                  name: "format-type",
                                  id: "format-type-label",
                                }}
                              >
                                <MenuItem aria-label="None" value="" />
                                <MenuItem value={"PDF"}>PDF</MenuItem>
                                <MenuItem value={"Jpeg"}>JPEG</MenuItem>
                                <MenuItem value={"Ms-word"}>MS Word</MenuItem>
                                <MenuItem value={"Picture"}>Picture</MenuItem>
                                <MenuItem value={"Scan"}>Scan</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="formatType"
                            rules={{ required: true }}
                            error={!!errors.formatType}
                          />
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                  ) : null}
                  {/* Transcription */}
                  {sT === "Transcription" ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="transcription-type-label">
                            Type of Transcription
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Type of Transcription"
                                inputProps={{
                                  name: "transcription-type",
                                  id: "transcription-type-label",
                                }}
                                value=""
                              >
                                <MenuItem aria-label="None" value="" />
                                <MenuItem value={"Audio / Video"}>
                                  Audio / Video
                                </MenuItem>
                                <MenuItem value={"Audio"}>Audio</MenuItem>
                                <MenuItem value={"Video"}>Video</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="transcriptionType"
                            rules={{ required: true }}
                            error={!!errors.transcriptionType}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <FormControl variant="outlined" size="small" fullWidth>
                          <InputLabel htmlFor="format-type-label">
                            Format Type
                          </InputLabel>
                          <Controller
                            as={
                              <Select
                                label="Format Type"
                                inputProps={{
                                  name: "format-type",
                                  id: "format-type-label",
                                }}
                              >
                                <MenuItem aria-label="None" value="" />
                                <MenuItem value={"Youtube"}>Youtube</MenuItem>
                                <MenuItem value={"URL link"}>URL Link</MenuItem>
                                <MenuItem value={"CD"}>CD</MenuItem>
                                <MenuItem value={"DVD"}>Dvd</MenuItem>
                                <MenuItem value={"Mp3"}>Mp3</MenuItem>
                                <MenuItem value={"Mp4"}>mp4</MenuItem>
                                <MenuItem value={"Wav"}>Wav</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                              </Select>
                            }
                            control={control}
                            name="formatType"
                            rules={{ required: true }}
                            error={!!errors.formatType}
                          />
                        </FormControl>
                      </Grid>

                      {(showUrlLink === "URL link" ||
                        showUrlLink === "Youtube") && (
                        <Grid item xs={12} sm={6} md={12}>
                          <TextField
                            id="url-link"
                            label="URL Link"
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="urlLink"
                            inputRef={register({
                              required: true,
                              pattern: {
                                value:
                                  /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/,
                                message: "Invalid url",
                              },
                              minLength: {
                                value: 2,
                                message: "Must have at least 2 letters",
                              },
                              // maxLength: { value: 150, message: 'Maximum characters limit is 150' },
                            })}
                            multiline
                            rowsMax={4}
                            error={!!errors.urlLink}
                            helperText={
                              (errors.urlLink && errors.urlLink.message) ||
                              "Use ',' between URLs"
                            }
                          />
                        </Grid>
                      )}
                    </React.Fragment>
                  ) : null}

                  <Grid item xs={12}>
                    <FormControlLabel
                      inputRef={register}
                      control={
                        <Checkbox
                          name="isSplitInvoice"
                          color="primary"
                          defaultChecked={
                            booking !== undefined
                              ? booking.isSplitInvoice
                              : false
                          }
                        />
                      }
                      label="Split Invoice"
                    />
                  </Grid>

                  {/* {sT === 'Translation' || sT === 'Transcription' ? ( */}
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={12}>
                      <FileUploads
                        type="bookingFile"
                        id={booking._id}
                        open={isOpen}
                        handleClose={handleModalClose}
                        handleFiles={handleFiles}
                        validFiles={validFiles}
                        unsupportedFiles={unsupportedFiles}
                        handleOnSubmit={uploadFiles}
                        handleRemove={removeFile}
                        percentage={percentage}
                        message={message}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={handleClickOpen}
                      >
                        Upload Files
                      </Button>
                    </Grid>

                    {/* Filelists */}
                    <FileLists
                      fileList={fileList}
                      openImageModal={openImageModal}
                      documentDeleteHandler={documentDeleteHandler}
                    />
                  </React.Fragment>
                  {/* ) : null} */}
                  <Grid item xs={6}></Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Controller
                      as={
                        <KeyboardDatePicker
                          autoOk
                          format="dd/MM/yyyy"
                          id="dateOfBooking"
                          label={
                            sT === "Telephone" ||
                            sT === "Video Call" ||
                            sT === "Onsite"
                              ? "Date of Attendance"
                              : "Date of Return"
                          }
                          size="small"
                          variant="inline"
                          fullWidth
                          inputVariant="outlined"
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      }
                      control={control}
                      name="date"
                      rules={{ required: true }}
                      error={!!errors.date}
                    />
                  </Grid>
                  {sT === "Telephone" ||
                  sT === "Video Call" ||
                  sT === "Onsite" ? (
                    <React.Fragment>
                      <Grid item xs={12} sm={6} md={3}>
                        <Controller
                          as={
                            <KeyboardTimePicker
                              autoOk
                              ampm={false}
                              id="startTime"
                              label="Start Time"
                              size="small"
                              variant="inline"
                              fullWidth
                              inputVariant="outlined"
                            />
                          }
                          control={control}
                          name="startTime"
                          rules={{ required: true }}
                          error={!!errors.startTime}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <Controller
                          as={
                            <KeyboardTimePicker
                              autoOk
                              ampm={false}
                              id="endTime"
                              label="End Time"
                              size="small"
                              variant="inline"
                              fullWidth
                              inputVariant="outlined"
                            />
                          }
                          control={control}
                          name="endTime"
                          rules={{ required: true }}
                          error={!!errors.endTime}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            Type of Urgency
                          </FormLabel>

                          <RadioGroup
                            aria-label="typeOfUrgency"
                            name="urgency"
                            row
                            defaultValue={
                              booking.urgency !== undefined
                                ? booking.urgency
                                : ""
                            }
                          >
                            <FormControlLabel
                              value="urgent"
                              inputRef={register}
                              control={<Radio color="primary" />}
                              label="Urgent - Within 3 days"
                            />
                            <FormControlLabel
                              value="non-urgent"
                              inputRef={register}
                              control={<Radio color="primary" />}
                              label="Non Urgent - After 5 days"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </React.Fragment>
                  )}
                  {/* Notes */}
                  <Grid item xs={12}>
                    <TextField
                      id="notes"
                      label="Notes / Special instructions"
                      size="small"
                      fullWidth
                      variant="outlined"
                      multiline
                      rows={3}
                      name="notes"
                      inputRef={register({
                        required: true,
                        minLength: {
                          value: 2,
                          message: "Must have at least 2 letters",
                        },
                        // maxLength: { value: 255, message: 'Maximum characters limit is 255' },
                      })}
                      error={!!errors.notes}
                      helperText={errors.notes && errors.notes.message}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            </Paper>
            {/* Estimated values */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.paper} variant="outlined">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <Autocomplete
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        options={isInterpreters}
                        loading={loaded}
                        filterSelectedOptions
                        getOptionLabel={(option) => option.fullname}
                        getOptionSelected={(option, value) =>
                          option.fullname === value.interpreterName
                        }
                        defaultValue={{
                          fullname:
                            booking.interpreterName !== undefined
                              ? booking.interpreterName
                              : "",
                        }}
                        renderOption={(option) => <>{option.fullname}</>}
                        onInputChange={onInputChange}
                        onChange={(e, value) => {
                          handleInterpreterIdChange(
                            value !== null ? value._id : "",
                            "interpreter"
                          );
                          handleInterpreterChange(
                            value !== null ? value.fullname : "",
                            "interpreterName"
                          );
                          handleInterpreterChange(
                            value !== null ? value.mobile : "",
                            "interpreterMobile"
                          );
                          handleInterpreterChange(
                            value !== null ? value.phone : "",
                            "interpreterPhone"
                          );
                          handleInterpreterChange(
                            value !== null ? value.postcode : "",
                            "interpreterPostcode"
                          );
                          handleInterpreterChange(
                            value !== null ? value.int_id : "",
                            "interpreterRef"
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a Interpreter"
                            defaultValue={undefined}
                            size="small"
                            fullWidth
                            variant="outlined"
                            name="interpreterName"
                            error={!!errors.interpreterName}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled",
                            }}
                          />
                        )}
                      />
                      <input
                        style={{ display: "none" }}
                        name="interpreterName"
                        ref={register({ required: false })}
                      />
                      <input
                        style={{ display: "none" }}
                        name="interpreter"
                        ref={register({ required: false })}
                        defaultValue={
                          booking !== undefined ? booking.interpreter : ""
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        name="interpreterMobile"
                        ref={register({ required: false })}
                        defaultValue={
                          booking !== undefined ? booking.interpreterMobile : ""
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        name="interpreterPhone"
                        ref={register({ required: false })}
                        defaultValue={
                          booking !== undefined ? booking.interpreterPhone : ""
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        name="interpreterPostcode"
                        ref={register({ required: false })}
                        defaultValue={
                          booking !== undefined
                            ? booking.interpreterPostcode
                            : ""
                        }
                      />
                      <input
                        style={{ display: "none" }}
                        name="interpreterRef"
                        ref={register({ required: false })}
                        defaultValue={
                          booking !== undefined ? booking.interpreterRef : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        style={{ minWidth: 220 }}
                        fullWidth
                      >
                        <InputLabel htmlFor="billing-rates-label">
                          Client Billing Rates
                        </InputLabel>
                        <Controller
                          as={
                            <Select
                              label="Client Billing Rates"
                              inputProps={{
                                name: "billing-rates",
                                id: "billing-rates-label",
                              }}
                            >
                              {isRateStructures.map((data) => (
                                <MenuItem key={data._id} value={data._id}>
                                  {data.rateName}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                          control={control}
                          name="billingRate"
                          rules={{ required: false }}
                          error={!!errors.billingRate}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <FormControlLabel
                        inputRef={register}
                        control={
                          <Checkbox
                            name="minClientAttendance"
                            color="primary"
                            defaultChecked={
                              booking !== undefined
                                ? booking.minClientAttendance
                                : false
                            }
                          />
                        }
                        label="Minimum Client Attendance"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                      <FormControlLabel
                        inputRef={register}
                        control={
                          <Checkbox
                            name="minInterpreterAttendance"
                            color="primary"
                            defaultChecked={
                              booking !== undefined
                                ? booking.minInterpreterAttendance
                                : false
                            }
                          />
                        }
                        label="Minimum Interpreter Attendance"
                      />
                    </Grid>
                  </Grid>
                </Paper>

                {booking?.previewBtn && (
                  <Grid item xs={3}>
                    <Button
                      color="primary"
                      startIcon={<AssignmentTurnedInIcon />}
                      onClick={quotationFormBtn}
                    >
                      {booking?.status !== "Quotation"
                        ? "Booking confirmation"
                        : "Generate Quotation"}
                    </Button>
                  </Grid>
                )}

                {/* {!isUpdated && billingRateCheck && interpreterNameCheck && (boS === 'Booking' || boS === 'Quotation') && (
                  <Grid item md={4}>
                    <Tooltip title="Enable Quotation">
                      <FormControlLabel
                        inputRef={register}
                        control={<Checkbox name="rePrepare" color="primary" onChange={handleRePrepareChange} checked={isUpdated} />}
                        label={boS === 'Booking' ? 'Booking confirmation' : 'Quotation'}
                      />
                    </Tooltip>
                  </Grid>
                )} */}
              </Grid>
              {/* 
              <Grid item xs={12} sm={6} md={8}>
                <Paper className={classes.paper} variant="outlined"></Paper>
              </Grid> */}
              {/* End table */}
            </Grid>

            <CardActions>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <Button size="small" color="primary" onClick={handleBack}>
                  Back
                </Button>
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isDirty && isValid}
                >
                  Update
                </Button>
              </Grid>
            </CardActions>
          </React.Fragment>
        )}
      </form>
      <Loader open={isLoading} handleClose={backdropCloseHandler} />
      <ViewFile
        previewFile={viewDoc}
        open={isModal}
        handleClose={toggleModal}
      />

      {isQForm && quotationForm}
      {/* <PrepareQuotation
        open={isQForm}
        handleClose={quotationFormBtn}
        classes={classes}
        data={formValue}
        isRate={isRate}
        isMinRates={isMinRates}
        onSubmitHandler={quotationOnSubmit}
      /> */}
    </>
  );
});
